import {
  RichtextChangeEventDetail,
  RichtextSelectionChangeEventDetail,
  RichtextUploadRequestEventDetail,
} from '@wppopen/components-library'
import { WppRichtextCustomEvent } from '@wppopen/components-library/dist/types/components'
import { WppButton, WppIconExport, WppRichtext } from '@wppopen/components-library-react'
import { jsPDF } from 'jspdf'
import { useCallback, useEffect, useState } from 'react'

import './TextEditor.css'

import { useMarkets } from 'api/queries/markets/useMarkets'
import { useRegions } from 'api/queries/markets/useRegions'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'

import { RichTextPageProps } from './types'
import { setImageAttributesFromDomNode, updateImageAttributesInString } from './utils'

const modules = JSON.stringify({
  toolbar: {
    aliases: {
      // Add image, video and attachments buttons to the embed section of toolbar
      embed: ['link', 'image', 'video', 'attachment'],
    },
  },
  // Enable custom upload handler for image, video and attachment
  imageUpload: true,
  videoUpload: true,
  attachmentUpload: true,
})

function upload(file: File): Promise<string> {
  return new Promise(resolve => {
    const delay = Math.floor(Math.random() * 10000)

    setTimeout(() => resolve(URL.createObjectURL(file)), delay)
  })
}

export const RichTextPage = ({ questions, rfiData }: RichTextPageProps) => {
  const { data: pitchTypes } = usePitchTypes()
  const { data: markets = [] } = useMarkets()
  const { data: regions = [] } = useRegions()

  const [value, setValue] = useState('')
  useEffect(() => {
    if (pitchTypes.length === 0 || markets.length === 0 || questions.length === 0) return

    let tmp = `
      <h2>
          <strong><span class="ql-size-m">${rfiData.projectName}</span></strong>
      </h2>
      <p>
          <span class="ql-size-xs">Project Name</span>
      </p>
      <br />

      <h2>
          <strong><span class="ql-size-m">${rfiData.clientName}</span></strong>
      </h2>
      <p>
          <span class="ql-size-xs">Client name</span>
      </p>
      <br />
      

      <h2>
      <strong><span class="ql-size-m">${pitchTypes?.find(p => p.id === rfiData.pitchTypeId)?.typeDescription ?? ''}</span></strong>
      </h2>
      <p class="ql-size-m">
      <span class="ql-size-xs">Pitch Type</span>
      </p>
      <br />


      <h2>
          <strong><span class="ql-size-m">${rfiData.marketIds
            ?.map(market => {
              return [...regions, ...markets]?.find(p => p.id === market)?.name ?? ''
            })
            .join(', ')}</span></strong>
      </h2>
      <p>
          <span class="ql-size-xs">Markets</span>
      </p>
      <br />
    `

    questions.forEach(category => {
      tmp += `
        <h2>
          <strong><span class="ql-size-m">${category.name}</span></strong>
        </h2>

        ${category.questions
          .map(question => {
            const proposedAnswer = (
              question?.latestChildQuestionProposedAnswer ||
              question.proposedAnswer ||
              'Answer not validated/provided'
            ).replace(/\n/g, '<br />')
            return `
            <h4>
                <span class="ql-size-m">${question.questionText}</span>
            </h4>
            <p>
                <span class="ql-size-xs">${proposedAnswer}</span>
            </p>
            <br />
            `
          })
          .join('')}
        `
    })
    setValue(tmp)
  }, [rfiData, pitchTypes, markets, questions, regions])

  const handleChange = useCallback((e: WppRichtextCustomEvent<RichtextChangeEventDetail>) => {
    setValue(e.detail.value)
  }, [])

  const handleSelectionChange = useCallback((e: WppRichtextCustomEvent<RichtextSelectionChangeEventDetail>) => {
    console.log('wppSelectionChange', { ...e.detail.range }, e)
  }, [])

  // There is also need to enable respective embed button in toolbar (image, video and attachment)
  // and respective upload modules (imageUpload, videoUpload and attachmentUpload)
  const handleUploadRequest = useCallback((e: WppRichtextCustomEvent<RichtextUploadRequestEventDetail>) => {
    console.log('wppUploadRequest', e)

    const type = e.detail.type
    const callback = e.detail.callback
    const input = document.createElement('input')

    input.type = 'file'
    input.accept = type === 'attachment' ? '*' : `${type}/*`
    input.multiple = true
    input.onchange = () => {
      const uploadItems = Array.from(input.files!).map(file => ({
        file,
        promise: upload(file),
      }))

      callback(uploadItems)
    }
    input.click()
  }, [])

  const generatePDF = () => {
    let pdf = new jsPDF('p', 'pt', 'a4')
    const html = document.querySelector('.ql-container.ql-wpp .ql-editor')

    const imgAttributes = setImageAttributesFromDomNode(html as HTMLElement)
    const htmlString = updateImageAttributesInString(value, imgAttributes)

    const wrappedValue = `<div style="width: 500px; position: relative" data-p>${htmlString}</div>`
    const Yoffset = 50
    // Find all image elements in the HTML string
    const images = wrappedValue.match(/<img[^>]+>/g)

    if (images) {
      // Loop through each image element and add it to the PDF document
      images.forEach((image, index) => {
        // Extract the image URL and dimensions
        const imageUrlMatch = /src="([^"]+)"/.exec(image)
        const imageUrl = imageUrlMatch ? imageUrlMatch[1] : ''
        const { width, height } = pdf.getImageProperties(imageUrl)
        // Extract the top and left attributes of the image
        const top = imgAttributes[index].top ?? 0

        // Add image to the PDF document
        pdf.addImage(imageUrl, 'JPG', 50, top + Yoffset, width, height)
      })
    }

    pdf.html(wrappedValue, {
      callback: function (pdf) {
        pdf.save(`${rfiData.projectName}.pdf`)
      },
      autoPaging: 'text',
      margin: 50,
    })
  }

  return (
    <>
      <WppRichtext
        name="content"
        value={value}
        modules={modules}
        onWppChange={handleChange}
        onWppSelectionChange={handleSelectionChange}
        onWppUploadRequest={handleUploadRequest}
        required
        className="mt-10"
      />

      <div className="mt-6 flex justify-end">
        <WppButton size="m" onClick={() => generatePDF()}>
          <WppIconExport slot="icon-start" />
          Export
        </WppButton>
      </div>
    </>
  )
}
