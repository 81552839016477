import { EditorState, Modifier } from 'draft-js'

import { RfiQuestion } from 'types/rfis/rfi'

import { MessageType, ProjectActionType } from './types'
import { Role } from '../../types/users/userList'

export const mergeQuestions = (rfiQuestion: RfiQuestion | null): RfiQuestion[] =>
  (rfiQuestion && [rfiQuestion, ...(rfiQuestion?.childQuestions || [])]) || []

export const updateMergedConversation = (questions: RfiQuestion[]) =>
  questions
    .map(question => {
      const questionMessage = {
        id: `${question.id}-${MessageType.QUESTION}`,
        type: MessageType.QUESTION,
        content: question.questionText,
        timestamp: new Date().toISOString(),
        rfiQuestionId: question.id,
        parentId: question.parentId,
        comments: question.questionComments,
      }
      const answerMessage = {
        id: `${question.id}-${MessageType.ANSWER}`,
        type: MessageType.ANSWER,
        content: question.proposedAnswer,
        timestamp: new Date().toISOString(),
        rfiQuestionId: question.id,
        parentId: question.parentId,
        comments: question.answerComments,
      }
      return [questionMessage, answerMessage]
    })
    .flat()

// https://github.com/jpuri/draftjs-utils/blob/master/js/block.js
export const getResetEditorState = (editorState: EditorState) => {
  const blocks = editorState.getCurrentContent().getBlockMap().toList()
  const updatedSelection = editorState.getSelection().merge({
    anchorKey: blocks.first().get('key'),
    anchorOffset: 0,
    focusKey: blocks.last().get('key'),
    focusOffset: blocks.last().getLength(),
  })
  const newContentState = Modifier.removeRange(editorState.getCurrentContent(), updatedSelection, 'forward')
  const newState = EditorState.push(editorState, newContentState, 'remove-range')
  return newState
}

const {
  ASK_RFI_QUESTION,
  EDIT_RFI_QUESTION,
  ADD_COMMENT,
  EDIT_COMMENT,
  DELETE_COMMENT,
  VIEW_COMMENT,
  COPY_TO_CLIPBOARD,
} = ProjectActionType

export const rolesAndPermissions: Record<keyof typeof Role, ProjectActionType[]> = {
  Owner: Object.keys(ProjectActionType) as ProjectActionType[],
  Viewer: [VIEW_COMMENT, COPY_TO_CLIPBOARD],
  Commenter: [VIEW_COMMENT, ADD_COMMENT, COPY_TO_CLIPBOARD, EDIT_COMMENT, DELETE_COMMENT],
  Contributor: [
    VIEW_COMMENT,
    COPY_TO_CLIPBOARD,
    ADD_COMMENT,
    EDIT_COMMENT,
    DELETE_COMMENT,
    ASK_RFI_QUESTION,
    EDIT_RFI_QUESTION,
  ],
}

export const hasRequiredPermission = (role: keyof typeof Role, actions?: ProjectActionType[]) => {
  return role === Role.Owner || actions?.every(action => rolesAndPermissions[role]?.includes(action))
}

export const isCommentOwner = (createByEmail: string, currentUserEmail: string) => {
  return createByEmail === currentUserEmail
}
